import './App.css';

import { useEffect, useRef } from 'react';
import pdf from "./PDF/av.pdf"
import data from "./Data.json";
function App() {


  console.log(pdf)
  const onButtonClick = () => {
    fetch("https://silentrend.com/wp-content/uploads/2022/11/JD-Junior-Front-End-Developer.pdf").then(response => {
      response.blob().then(blob => {
        const fileURL = window.URL.createObjectURL(blob);
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'name.pdf';
        alink.click();
      })
    })
  }
  return (
    <div>

      <center>  <h1>Welcome to Geeks for Geeks</h1>
        {data.map((val) => {
          return (
            <>
              <div className='card'>
                <h3> this name of pdf is {pdf}</h3>
                <button onClick={onButtonClick}>
                  Download PDF
                </button></div></>
          )
        }
        )}

      </center>


    </div>
  );
}





export function PdfViewerComponent(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let PSPDFKit;

    (async function () {
      PSPDFKit = await import('pspdfkit');
      PSPDFKit.load({
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document: props.document,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
      });
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, [props.document]);

  return (
    <div
      ref={containerRef}
      style={{ width: '100%', height: '100vh' }}
    />
  );
}

export default App;
